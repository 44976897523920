@if (this.pCardInfo) {
<div class="row d-flex justify-content-around align-items-start">
  <div class="col-12 p-0 d-none d-md-block">
    <h1 class="mt-2 m-0">{{ this.pCardInfo.cardName }}</h1>
  </div>
  <div class="col-lg-6 col-md-12 row p-0 m-0">
    <app-pic-carousel
      [enzaId]="this.pCardInfo.enzaId"
      [displayMov1]="this.isSsrCard() && !this.isIdolRoad()"
      [displayMov2]="this.isSsrCard() || this.isSrCard()"
      [cardName]="this.pCardInfo.cardName"
      class="p-2">
    </app-pic-carousel>
    <div class="container col-12 row m-0 p-0 pt-1">
      <app-common-criteria
        [releaseDate]="this.pCardInfo.releaseDate"
        [getMethod]="this.translateGetMethod()"
        [rarity]="this.pCardInfo.cardType"></app-common-criteria>

      <app-card-status [cardStatus]="this.pCardInfo.cardStatus"></app-card-status>
      @if (this.pCardInfo.cardIdolEvents.length) {
      <h3
        class="col-12 pt-2"
        i18n>イベント</h3>
      }
      @if (this.pCardInfo.cardIdolEvents.length) {
      <div
        class="col-lg-6 col-md-12 size18">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col" i18n>事件名稱</th>
            </tr>
          </thead>
          <tbody>
            @for (event of this.getIdolEvents(); track event) {
            <tr>
              <td>
                <a
                  [href]="getEventUrl(event.eventId)"
                  class="d-flex"
                  target="_blank">
                  <svg class="align-self-center" fill="#3880ff" height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-3 -3 66.00 66.00" xml:space="preserve" stroke="#3880ff" stroke-width="4.5"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path
                          d="M45.563,29.174l-22-15c-0.307-0.208-0.703-0.231-1.031-0.058C22.205,14.289,22,14.629,22,15v30 c0,0.371,0.205,0.711,0.533,0.884C22.679,45.962,22.84,46,23,46c0.197,0,0.394-0.059,0.563-0.174l22-15 C45.836,30.64,46,30.331,46,30S45.836,29.36,45.563,29.174z M24,43.107V16.893L43.225,30L24,43.107z"></path> <path d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30 S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z"></path>
                      </g> </g></svg>
                  <div class="ps-1 align-middle">{{ event.eventTitle }}</div>
                </a>
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>
      }
      @if (this.pCardInfo.cardIdolEvents.length) {
      <div
        class="col-lg-6 col-md-12 size18">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">True End</th>
            </tr>
          </thead>
          <tbody>
            @for (event of this.getTrueEnd(); track event) {
            <tr>
              <td>
                <a
                  [href]="getEventUrl(event.eventId)"
                  class="d-flex"
                  target="_blank">
                  <svg class="align-self-center" fill="#3880ff" height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-3 -3 66.00 66.00" xml:space="preserve" stroke="#3880ff" stroke-width="4.5"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path
                          d="M45.563,29.174l-22-15c-0.307-0.208-0.703-0.231-1.031-0.058C22.205,14.289,22,14.629,22,15v30 c0,0.371,0.205,0.711,0.533,0.884C22.679,45.962,22.84,46,23,46c0.197,0,0.394-0.059,0.563-0.174l22-15 C45.836,30.64,46,30.331,46,30S45.836,29.36,45.563,29.174z M24,43.107V16.893L43.225,30L24,43.107z"></path> <path d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30 S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z"></path>
                      </g> </g></svg>
                  <div class="ps-1 align-middle">{{ event.eventTitle }}</div>
                </a>
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>
      }
      <h3 class="col-12" i18n>適性</h3>
      <div class="col-12">
        <app-aptitude [apts]="this.pCardInfo.cardProduceAptitude"></app-aptitude>
      </div>
      <h3 i18n>思い出</h3>
      <div class="col-12">
        <app-memory-table [memoryList]="this.getMemoryAppeal()"></app-memory-table>
      </div>
    </div>
  </div>
  <div class="col-lg-6 col-md-12 row p-0 m-0">
    <app-panel-info
      class="col-12 noFontSize p-2"
      [panelInfo]="this.pCardInfo.cardPanels"
      [isSr]="this.isSrCard()"
      (stateChanged)="updateState($event)"></app-panel-info>
    <div class="container col-12 pt-2 row m-0 p-0">
      <app-panel-list
        [panelInfo]="this.pCardInfo.cardPanels"
        [highlited]="this.highlight"></app-panel-list>
    </div>
  </div>
</div>
}
