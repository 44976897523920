import { ExtensionType, utils, settings, extensions } from "@pixi/core";
const resolveCompressedTextureUrl = {
  extension: ExtensionType.ResolveParser,
  test: value => {
    const extension = utils.path.extname(value).slice(1);
    return ["basis", "ktx", "dds"].includes(extension);
  },
  parse: value => {
    const extension = utils.path.extname(value).slice(1);
    if (extension === "ktx") {
      const extensions2 = [".s3tc.ktx", ".s3tc_sRGB.ktx", ".etc.ktx", ".etc1.ktx", ".pvrt.ktx", ".atc.ktx", ".astc.ktx"];
      if (extensions2.some(ext => value.endsWith(ext))) return {
        resolution: parseFloat(settings.RETINA_PREFIX.exec(value)?.[1] ?? "1"),
        format: extensions2.find(ext => value.endsWith(ext)),
        src: value
      };
    }
    return {
      resolution: parseFloat(settings.RETINA_PREFIX.exec(value)?.[1] ?? "1"),
      format: extension,
      src: value
    };
  }
};
extensions.add(resolveCompressedTextureUrl);
export { resolveCompressedTextureUrl };
