<table class="table col-12">
  <thead>
    <tr>
      <th>#</th>
      <th scope="col" i18n>技能名稱</th>
      <th scope="col" i18n>Link</th>
    </tr>
  </thead>
  @for(mem of memoryList; track mem; let i = $index) {
  <tbody>
    <tr>
      <td rowspan="2" class="align-middle forLevel">Lv.{{ i + 1 }}</td>
      <td>{{ mem.memoryTitle }}</td>
      <td rowspan="2" class="align-middle">
        @if(mem.extraEffect) {
        <span class="wrapup">{{ this.addLineBreak(mem.extraEffect.extraEffectDesc) }}</span>
        }
      </td>
    </tr>
    <tr>
      <td class="align-middle">
        <span class="wrapup">{{ this.addLineBreak(mem.memoryDesc) }}</span>
      </td>
    </tr>
  </tbody>
  }

</table>
