import { ExtensionType, settings, extensions as extensions$1 } from "@pixi/core";
let storedGl, extensions;
function getCompressedTextureExtensions() {
  extensions = {
    s3tc: storedGl.getExtension("WEBGL_compressed_texture_s3tc"),
    s3tc_sRGB: storedGl.getExtension("WEBGL_compressed_texture_s3tc_srgb"),
    /* eslint-disable-line camelcase */
    etc: storedGl.getExtension("WEBGL_compressed_texture_etc"),
    etc1: storedGl.getExtension("WEBGL_compressed_texture_etc1"),
    pvrtc: storedGl.getExtension("WEBGL_compressed_texture_pvrtc") || storedGl.getExtension("WEBKIT_WEBGL_compressed_texture_pvrtc"),
    atc: storedGl.getExtension("WEBGL_compressed_texture_atc"),
    astc: storedGl.getExtension("WEBGL_compressed_texture_astc")
  };
}
const detectCompressedTextures = {
  extension: {
    type: ExtensionType.DetectionParser,
    priority: 2
  },
  test: async () => {
    const gl = settings.ADAPTER.createCanvas().getContext("webgl");
    return gl ? (storedGl = gl, !0) : (console.warn("WebGL not available for compressed textures."), !1);
  },
  add: async formats => {
    extensions || getCompressedTextureExtensions();
    const textureFormats = [];
    for (const extensionName in extensions) extensions[extensionName] && textureFormats.push(extensionName);
    return [...textureFormats, ...formats];
  },
  remove: async formats => (extensions || getCompressedTextureExtensions(), formats.filter(f => !(f in extensions)))
};
extensions$1.add(detectCompressedTextures);
export { detectCompressedTextures };
